// import { Container, Typography, useTheme } from '@mui/material';
import React from "react";
// import { tokens } from '../../theme';
// import { Helmet } from 'react-helmet-async';
import JobPost from "../../components/TalentConnect/JobPost";

const JobPostPage = () => {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  return (
    <>
      <JobPost />
    </>
  );
};

export default JobPostPage;
