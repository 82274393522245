import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, useTheme } from "@mui/material";
import { tokens } from "../../../theme";

export default function DonutChart({ data, isLoading, isAdmin }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const covertData = data?.map(item => ({
    name: item.title,
    value: item.count
  }));

  const options = {
    series: covertData?.map((item) => item.value),
    labels: covertData?.map((item) => item.name),
    chart: {
      type: "donut",
      height: isAdmin?135:125, // Adjust the height to your preference
      animations: {
        enabled: true, // Enable animations
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          size: "40%", // Adjust the size of the donut hole
        },

      },
    },
    stroke: {
      show: true,
      // width: 2,
      colors: [colors.primary[400]],
    },
    legend: {
      show: true, // Hide the legend (labels)
      // fontSize: "12px",
      offsetY:isAdmin?15:'',
    },
  };

  return (
    <Card
      elevation={isLoading ? 4 : 0}
      sx={{
        // p: 1,
        bgcolor:isLoading?colors.blueAccent[800]:colors.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width:'100%',
      }}
    >
      <ReactApexChart
        //
        options={options}
        series={options.series}
        type={options.chart.type}
        height={options.chart.height}
      />
    </Card>
  );
}

// import React, { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
// import { Card, colors, useTheme } from "@mui/material";
// import { tokens } from "../../../theme";

// export default function DonutChart({ data, isLoading }) {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const convertData = data?.map((item) => ({
//     name: item.title,
//     value: item.count,
//   }));

//   const [chartOptions, setChartOptions] = useState({
//     series: [],
//     labels: [],
//     chart: {
//       type: "donut",
//       height: 125, // Adjust the height to your preference
//       animations: {
//         enabled: false, // Disable initial animations
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     plotOptions: {
//       pie: {
//         startAngle: -90,
//         endAngle: 270,
//         donut: {
//           size: "40%", // Adjust the size of the donut hole
//         },
//       },
//     },
//   });

//   useEffect(() => {
//     if (!isLoading && data) {
//       const convertedData = data.map((item) => ({
//         name: item.title,
//         value: item.count,
//       }));

//       // Update chart options to enable animations
//       setChartOptions((prevOptions) => ({
//         ...prevOptions,
//         animations: {
//           enabled: true,
//         },
//         series: convertedData.map((item) => item.value),
//         labels: convertedData.map((item) => item.name),
//       }));
//     }
//   }, [data, isLoading]);

//   return (
//     <Card
//       elevation={isLoading ? 4 : 0}
//       sx={{
//         bgcolor: isLoading ? colors.blueAccent[800] : colors.primary,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "100%",
//         width: "100%",
//       }}
//     >
//       <ReactApexChart
//         options={chartOptions}
//         series={chartOptions.series}
//         type={chartOptions.chart.type}
//         height={chartOptions.chart.height}
//       />
//     </Card>
//   );
// }
