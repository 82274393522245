// import { Box, Container, Typography, useTheme } from "@mui/material";
import React from "react";
// import { tokens } from "../../theme";
// import { Helmet } from "react-helmet-async";
import JobDescription from "../../components/TalentConnect/JobDescription";

const JobDescriptionPage = () => {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  return (
    <>
      <JobDescription />
    </>
  );
};

export default JobDescriptionPage;
