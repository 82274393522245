
import React from "react";
import { Helmet } from "react-helmet-async";

const TalentConnect = () => {
  return (
    <>
      <Helmet>
        <title> TalentConnect | EduSkills </title>
      </Helmet>
     
    </>
  );
};

export default TalentConnect;
