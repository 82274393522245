import React from "react";

import { Helmet } from "react-helmet-async";
const Resume = () => {
  return (
    <>
      <Helmet>
        <title> Resume | EduSkills </title>
      </Helmet>
    </>
  );
};

export default Resume;
