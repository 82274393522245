import React from "react";
import { Helmet } from "react-helmet-async";

const AwardRecognition = () => {
  return (
    <div>
      <Helmet>
        <title> Awards Recognition | EduSkills </title>
      </Helmet>
      Awards Recognition
    </div>
  );
};

export default AwardRecognition;
