import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import NonAuthLayout from "../Layouts/NonAuthLayout";
import Layout from "../Layouts";
import { publicRoutes, authProtectedRoutes } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";

const Index = () => {
  const userRole = useSelector((state) => state.authorise.userRole);
  const [isLoggedIn, setIsLoggedIn] = useState(!!userRole);

  useEffect(() => {
    setIsLoggedIn(!!userRole);
  }, [userRole, isLoggedIn]);
  
  console.log("User Role:", userRole);
  console.log("Is Logged In:", isLoggedIn);

  const combinedRoutes = [
    ...publicRoutes.map((route) => ({ ...route, type: 'public' })),
    ...authProtectedRoutes
      // .filter((route) => !route.roles || route.roles.includes(userRole))
      .map((route) => ({ ...route, type: 'protected' }))
  ];

  return (
    <React.Fragment>
      <Routes>
        {combinedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              // route.type === 'public' ? (
              //   <NonAuthLayout>{route.component}</NonAuthLayout>
              // ) :
               (
                <Layout>{route.component}</Layout>
                // <AuthProtected>
                //   <Layout>{route.component}</Layout>
                // </AuthProtected>
              )
            }
            key={idx}
            exact={route.exact || false}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

export default Index;
