import { Icon } from "@iconify/react";
import { AutoMode, Book, CorporateFare } from "@mui/icons-material";
// -----------------------------------------------------------------
const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <Icon icon="mingcute:classify-2-fill" height={22} width={22} />,
  },
  {
    title: "Internship",
    path: "/internship",
    icon: <Icon icon="vaadin:academy-cap" height={22} width={22} />,
  },

  {
    title: "Student Info",
    path: "/internship-all",
    icon: (
      <Icon icon="material-symbols:event-note-rounded" height={22} width={22} />
    ),
  },

  {
    title: "Academy Program",
    path: "/academy-program",
    icon: <Icon icon="mdi:google-classroom" height={22} width={22} />,
  },

  // Admin....................................

  {
    title: "Institute",
    path: "/institute",
    icon: <Icon icon="fa:university" height={22} width={22} />,
  },
  {
    title: "Academy",
    path: "/academy",
    icon: <Icon icon="ic:round-corporate-fare" height={22} width={22} />,
  },
  {
    title: "Educators",
    path: "/admin-educator",
    icon: <Icon icon="ph:chalkboard-teacher-bold" height={22} width={22} />,
  },

  //............................................

  {
    title: "Awards & Recognition",
    path: "/awards-recognition",
    icon: <Icon icon="healthicons:award-trophy" height={25} width={25} />,
  },
  {
    title: "Institution Status",
    path: "/institution-status",
    icon: <AutoMode />,
  },
  {
    title: "Corporate",
    path: "/corporate-program",
    icon: <Icon icon="healthicons:i-training-class" height={25} width={25} />,
  },

  // {
  //   title: "Event Details",
  //   path: "/event-details",
  //   icon: <Icon icon="ic:round-event" height={25} width={25} />,
  // },
  // {
  //   title: "Educator",
  //   path: "/educator",
  //   icon: <Icon icon="icon-park-solid:classroom" height={22} width={22} />,
  // },
  // {
  //   title: "Certificate",
  //   path: "/certificate",
  //   icon: <Icon icon="mdi:certificate" height={22} width={22} />,
  //   children: [
  //     {
  //       title: "Apshce Certificate",
  //       path: "/apsche-certificate",
  //       parent: "Certificate",
  //       icon: (
  //         <Icon icon="icon-park-twotone:agreement" height={22} width={22} />
  //       ),
  //     },
  //   ],
  // },
  {
    title: "Membership",
    path: "/membership",
    icon: (
      <Icon icon="material-symbols:card-membership" height={22} width={22} />
    ),
    children: [
      {
        title: "Agreements",
        path: "/membership/agreements",
        parent: "Membership",
        icon: (
          <Icon icon="icon-park-twotone:agreement" height={22} width={22} />
        ),
      },
      // {
      //   title: "Payment",
      //   path: "/membership/payment",
      //   parent: "Membership",
      //   icon: <Icon icon="mdi:account-payment" height={22} width={22} />,
      // },
      {
        title: "Institutional Details",
        path: "/membership/institutional-details",
        parent: "Membership",
        icon: <Icon icon="icomoon-free:profile" height={22} width={22} />,
      },
    ],
  },
  {
    title: "Certificate",
    path: "/certificate",
    icon: <Icon icon="mdi:certificate" height={22} width={22} />,
    children: [
      {
        title: "Apshce Certificate",
        path: "/apsche-certificate",
        parent: "Certificate",
        icon: <Icon icon="tabler:certificate-2" height={22} width={22} />,
      },

      // {
      //   title: "Institutional Details",
      //   path: "/membership/institutional-details",
      //   parent: "Membership",
      //   icon: <Icon icon="icomoon-free:profile" height={22} width={22} />,
      // },
    ],
  },

  {
    title: "News & Events",
    path: "/news-events",
    icon: (
      <Icon icon="material-symbols:event-note-rounded" height={22} width={22} />
    ),
  },
  {
    title: "Publication",
    path: "/publication",
    icon: <Book />,
  },
  {
    title: "Talent Connect",
    path: "/talent-connect",
    icon: <Icon icon="icon-park-solid:connect" height={22} width={22} />,
  },

  {
    title: "Resume",
    path: "/resume",
    icon: <Icon icon="pepicons-pop:cv" height={22} width={22} />,
  },
  {
    title: "Staff",
    path: "/staff",
    icon: <Icon icon="clarity:employee-solid" height={22} width={22} />,
  },
  {
    title: "Placement",
    path: "/placement",
    icon: <Icon icon="ic:twotone-work" height={22} width={22} />,
    parent: "Placement",
    children: [
      {
        title: "Resume",
        path: "/placement",
        icon: <CorporateFare />,
      },
      {
        title: "Job Details",
        path: "/placement",
        icon: <CorporateFare />,
      },
      {
        title: "Interview",
        path: "/placement",
        icon: <CorporateFare />,
      },
    ],
  },
  {
    title: "Support",
    path: "/support",
    icon: (
      <Icon icon="fluent:person-support-20-filled" height={22} width={22} />
    ),
  },
  {
    title: "Report",
    // path: "/report",
    icon: <Icon icon="mdi:report-box" height={22} width={22} />,
    children: [
      {
        title: "Student",
        path: "/payment",
        icon: <CorporateFare />,
      },
      {
        title: "Resume",
        path: "/report",
        icon: <CorporateFare />,
      },
    ],
  },
  // Talaent Connect..................
  {
    title: "Company",
    path: "/company",
    icon: (
      <Icon
        icon="material-symbols:corporate-fare-rounded"
        height={22}
        width={22}
      />
    ),
  },

  {
    title: "Job Description",
    path: "/job-description",
    icon: <Icon icon="carbon:batch-job" height={22} width={22} />,
  },
  {
    title: "Job Post",
    path: "/job-post",
    icon: <Icon icon="material-symbols:post-outline" height={22} width={22} />,
  },
  {
    title: "Job Applications",
    path: "/applications",
    icon: <Icon icon="pepicons-print:cv" height={22} width={22} />,
  },
  {
    title: "Settings",
    path: "/settings",
    icon: <Icon icon="material-symbols:settings" height={22} width={22} />,
  },
];

const roleTitles = {
  spoc: [
    "Dashboard",
    "Internship",
    "Student Info",
    "Corporate",
    "Educator",

    // "Awards & Recognition",
    // "News & Events",
    // "Talent Connect",
    // "Publication",
    "Membership",
    "Certificate",

    // "Support",
  ],
  management: [
    "Dashboard",
    "Internship",
    "Educator",
    "Corporate",
    "Membership",
  ],
  educator: ["Dashboard", "Internship", "Academy Program", "Support"],
  manager: [
    "Dashboard",
    "Internship",
    "College List",
    "Academy Program",
    "Institutional Details",
    "Academy",
  ],
  account_manager: [
    "Dashboard",
    "Internship",
    "College List",
    "Academy Program",
    "Institutional Details",
  ],
  talent: [
    "Dashboard",
    "Company",
    "Job Description",
    "Job Post",
    "Job Applications",
    // "Placement",
    // "Resume",
    // "Report",
    // "Support"
  ],
  admin: [
    "Dashboard",
    // "Internship",
    "Institute",
    // "Academy Program",
    // "Institutional Details",
    "Educators",
    "Academy",
    "Staff",
    // "Educator",
    // "Settings",
  ],
  staff: [
    "Dashboard",
    "Internship",
    "Event Details",
    // "Institute",
    // "Academy Program",
    // "Institutional Details",
    //  "Educator",

    // "Academy",
  ],
};

const filterNavConfig = (roles) => {
  const allowedTitles = [];

  for (const role in roles) {
    if (roles[role]) {
      allowedTitles.push(...roleTitles[role]);
    }
  }

  const menuItems = navConfig
    .filter((item) => allowedTitles.includes(item.title))
    .map((item) => ({
      title: item.title,
      path: item.path,
      icon: item.icon,
      children: item.children,
    }));

  return menuItems;
};

export const MenuItems = () => {
  const authorise = localStorage.getItem("Authorise");
  const roles = {
    admin: authorise === "admin" ? true : false,
    staff: authorise === "staff" ? true : false,
    spoc: authorise === "spoc" ? true : false,
    manager: authorise === "manager" ? true : false,
    management: authorise === "management" ? true : false,
    educator: authorise === "educator" ? true : false,
    account_manager: authorise === "account_manager" ? true : false,
    talent: authorise === "talent" ? true : false,
  };

  return filterNavConfig(roles);
};
