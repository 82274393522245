// import { Container, Typography, useTheme } from "@mui/material";
import React from "react";
// import { tokens } from "../../theme";

import CompanyComponent from "../../components/TalentConnect/Category/Company";

const CompanyPage = () => {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  return (
    <>
      <CompanyComponent/>
    </>
  );
};

export default CompanyPage;
