import React from 'react'
import { Helmet } from 'react-helmet-async'

const AcademyProgram = () => {
  return (
    <div>
      <Helmet>
        <title> Academy Program | EduSkills </title>
      </Helmet>
      Academy Program
    </div>
  )
}

export default AcademyProgram