import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  Drawer,
  Button,
  Tooltip,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import { MenuItems } from "./navConfig";
import LogoDark from "../assets/imgs/logo-dark.svg";
import LogoLight from "../assets/imgs/logo-light.svg";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  isMobileView,
  setToggleDrawer,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSelection = () => {
    setSelected(title);
    if (isMobileView) {
      setToggleDrawer(false);
    }
  };
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={handleSelection}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ toggleDrawer, setToggleDrawer }) => {
  const menuItems = MenuItems();
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const isActiveRole = useSelector((state) => state.authorise.userRole);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < theme.breakpoints.values.lg
  );

  const [showFeedbackTooltip, setShowFeedbackTooltip] = useState(false);

  // const findParentItem = (childPath) => {
  //   // Find the parent menu item based on the child item's path
  //   const parentItem = menuItems.find((item) =>
  //     item.children ? item.children.some((child) => child.path === childPath) : item.path === childPath
  //   );
  //   return parentItem;
  // };

  useEffect(() => {
    const selectedItem = menuItems.find((nav) => nav.path === currentPath);
    setSelected(selectedItem?.title);
  }, [currentPath, menuItems]);

  useEffect(() => {
    // Find the current child item matching the path
    const selectedChild = menuItems.find((nav) => nav?.children);
    const selectedChildItem = selectedChild?.children.find(
      (nav) => nav.path === currentPath
    );
    if (selectedChildItem) {
      setSelected(selectedChildItem?.title);
    }
  }, [currentPath, menuItems]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < theme.breakpoints.values.lg);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [theme.breakpoints.values.lg]);

  const handleFeedbackClick = () => {
    localStorage.setItem("hasProvidedFeedback", "true");
    // You can also navigate to the feedback page here
    navigate("/feedback");
  };

  useEffect(() => {
    // Show the tooltip 5 seconds after the dashboard is opened
    const openTooltipTimeout = setTimeout(() => {
      setShowFeedbackTooltip(true);
    }, 5000);

    // Close the tooltip 10 seconds after opening
    const closeTooltipTimeout = setTimeout(() => {
      setShowFeedbackTooltip(false);
    }, 15000);

    // Cleanup timeouts when the component unmounts
    return () => {
      clearTimeout(openTooltipTimeout);
      clearTimeout(closeTooltipTimeout);
    };
  }, []);

  const feedbackMessages = [
    "Share Your Thoughts",
    "Your Feedback Matters",
    "We Value Your Input",
    // "Help Us Improve",
    "Click to Give Feedback",
  ];

  const randomMessage =
    feedbackMessages[Math.floor(Math.random() * feedbackMessages.length)];

  const feedbackButtonLabel =
    localStorage.getItem("hasProvidedFeedback") === "true"
      ? randomMessage
      : null;

  const SidebarContent = (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          p: "8px 22px !important",
          // color: `${colors.primary[100]} !important`,
          // color:colors.primary[100]
        },
        "& .pro-inner-item:hover": {
          color: `${colors.blueAccent[400]} !important`,
          borderLeft: `2px solid ${colors.blueAccent[400]}`,
          transition: "width 0.3s ease-out, background-color 0.3s ease-out",
        },
        "& .pro-menu-item.active": {
          color: `${colors.blueAccent[400]} !important`,
          borderLeft: `2px solid ${colors.blueAccent[400]}`,
          // marginRight:'8px',
          // backgroundColor:`${colors.blueAccent[800]} !important`
        },

        "& .logo": {
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          top: 0,
          zIndex: 100,
          bgcolor: colors.primary[400],
        },
        "& .logo img": {
          height: "70px",
          padding: "16px",
          transition: "width 0.3s ease-out",
        },
        bgcolor: `${colors.primary[400]} !important`,
        height: "100vh",
      }}
    >
      <Box
        sx={{
          [theme.breakpoints.down("lg")]: {
            display: "block",
          },
          height: "100%",
          background: `${colors.primary[400]} !important`,
        }}
      >
        <ProSidebar style={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box className="logo">
              <img
                src={theme.palette.mode === "dark" ? LogoLight : LogoDark}
                alt="Logo"
              />
            </Box>
            <Box
              sx={{
                p: "0px 6px",
                borderRadius: 1,
                mt: 3,
                fontSize: "8px",
                ml: -2,
                zIndex: 999,
                color: colors.blueAccent[900],
                bgcolor: colors.blueAccent[300],
                fontWeight: 900,
              }}
            >
              BETA
            </Box>
          </Box>
          <Menu iconShape="square" style={{ padding: 0, height: "100%" }}>
            {menuItems?.map((nav, idx) => (
              <div key={idx}>
                {nav?.children ? (
                  <SubMenu title={nav.title} icon={nav.icon}>
                    {nav?.children?.map((child, childIdx) => (
                      <Item
                        key={childIdx}
                        title={child.title}
                        to={child.path}
                        icon={child.icon}
                        selected={selected}
                        setSelected={setSelected}
                        setToggleDrawer={setToggleDrawer}
                        isMobileView={isMobileView}
                      />
                    ))}
                  </SubMenu>
                ) : (
                  <Item
                    title={nav.title}
                    to={nav.path}
                    icon={nav.icon}
                    selected={selected}
                    setSelected={setSelected}
                    setToggleDrawer={setToggleDrawer}
                    isMobileView={isMobileView}
                  />
                )}
              </div>
            ))}
          </Menu>
          {isActiveRole ===  "spoc" && (
            <Box sx={{ textAlign: "center", p: 1 }}>
              <Tooltip
                title={feedbackButtonLabel}
                open={showFeedbackTooltip}
                arrow
              >
                <Button
                  sx={{ width: "100%", textTransform: "initial" }}
                  color={currentPath === "/feedback" ? "info" : "inherit"}
                  variant="outlined"
                  startIcon={<Icon icon="fluent:person-feedback-16-regular" />}
                  onClick={handleFeedbackClick}
                >
                  Feedback
                </Button>
              </Tooltip>
            </Box>
          )}
        </ProSidebar>
      </Box>
    </Box>
  );

  return (
    <Box>
      {isMobileView ? (
        <Drawer
          variant="temporary"
          anchor="left"
          open={toggleDrawer}
          onClose={() => setToggleDrawer(false)}
        >
          {SidebarContent}
        </Drawer>
      ) : (
        SidebarContent
      )}
    </Box>
  );
};

export default Sidebar;
