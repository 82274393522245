import React from "react";

import { Helmet } from "react-helmet-async";



const Reports = () => {


  return (
    <>
      <Helmet>
        <title> Report | EduSkills </title>
      </Helmet>


    </>
  );
};

export default Reports;
