import React from 'react'
import TotalInternship from './TotalInternship'

const InternshipSection = () => {
  return (
    <>
    <TotalInternship/>
    </>
  )
}

export default InternshipSection