import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Index from "./Routes";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserRole } from "./store/Slices/auth/authoriseSlice";
import { AuthService } from "./services/dataService";
import { setTokens } from "./store/Slices/auth/authSlice";

function App() {
  const [theme, colorMode] = useMode();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    const initialize = async () => {
      const query = new URLSearchParams(window.location.search);
      const accessToken = query.get("accessToken");
      const refreshToken = query.get("refreshToken");
      const email = query.get("email");
      dispatch(
        setTokens({ accessToken: accessToken, refreshToken: refreshToken })
      );

      if (accessToken && refreshToken) {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("userName", email);
        window.history.replaceState({}, document.title, window.location.pathname);

        try {
          const rolesResponse = await AuthService.roles();
          const fetchedRoles = rolesResponse.data.roles;
          const activeRole = fetchedRoles.find((role) => role.status === true);

          if (activeRole) {
            const activeRoleNameModified = activeRole.role_name
              .toLowerCase()
              .replace(/ /g, "_");
            localStorage.setItem("Authorise", activeRoleNameModified);
            dispatch(setUserRole(activeRoleNameModified));
            navigate("/dashboard");
          } 
        } catch (error) {
          console.error("Error fetching roles:", error);
        }
      }else{
        
      }
    };

    initialize();
  }, [navigate, dispatch]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Index />
        <ToastContainer />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
