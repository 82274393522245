// // import React from "react";
// // import {
// //   Container,
// //   Typography,
// //   Box,
// //   Avatar,
// //   Grid,
// //   Paper,
// //   useTheme,
// //   Button,
// // } from "@mui/material";
// // import { Icon } from "@iconify/react";
// // import Barcode from "react-barcode";
// // import { addDays, format } from "date-fns";
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";

// // const Resume = ({ data }) => {
// //   const theme = useTheme();

// //   const currentDate = new Date();
// //   const validityEndDate = addDays(currentDate, 30);
// //   const formattedEndDate = format(validityEndDate, "MMM dd yyyy");
// //   const barcodeValue = `EduSkills Valid for ${formattedEndDate}`;
// //   const formattedDate = format(new Date("2024-04-12T05:55:38"), "dd MMM yyyy");
// //   const colors = {
// //     primary: "#263238",
// //     secondary: "#546e7a",
// //     accent: "#0E619F",
// //     background: "#ffff",
// //     white: "#ffff",
// //   };

// //   const headerBackground = {
// //     background: `linear-gradient(135deg, ${colors.accent} 0%, ${colors.secondary} 100%)`,
// //     color: colors.white,
// //     borderRadius: "5px 5px 0 0",
// //     padding: theme.spacing(4),
// //   };

// //   const downloadPDF = () => {
// //     const input = document.getElementById("resume-content");
// //     const pdf = new jsPDF("p", "mm", "a4");

// //     html2canvas(input).then((canvas) => {
// //       const imgData = canvas.toDataURL("image/png");
// //       const imgProps = pdf.getImageProperties(imgData);
// //       const pdfWidth = pdf.internal.pageSize.getWidth();
// //       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
// //       pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
// //       pdf.save(`${data.profile.name}_Resume.pdf`);
// //     });
// //   };

// //   return (
// //     <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
// //       <Paper
// //         id="resume-content"
// //         elevation={6}
// //         sx={{ bgcolor: colors.background, pb: 2 }}
// //       >
// //         <Box sx={headerBackground}>
// //           <Grid container spacing={2} alignItems="center">
// //             <Grid item xs={12} sm={4}>
// //               <Box sx={{ display: "flex", justifyContent: "center" }}>
// //                 <Avatar
// //                   src={data.profile.profile_pic}
// //                   sx={{ width: 140, height: 140 }}
// //                 />
// //               </Box>
// //             </Grid>
// //             <Grid item xs={12} sm={8}>
// //               <Typography
// //                 variant="h4"
// //                 sx={{ fontWeight: "bold", marginBottom: 1 }}
// //               >
// //                 {data.profile.name}
// //               </Typography>
// //               <Typography
// //                 variant="subtitle1"
// //                 sx={{ fontSize: "1.1rem", fontWeight: "medium" }}
// //               >
// //                 {data.objective}
// //               </Typography>
// //               <Box sx={{ mt: 2 }}>
// //                 <Typography
// //                   sx={{ display: "flex", alignItems: "center", mb: 1 }}
// //                 >
// //                   <Icon
// //                     icon="el:phone-alt"
// //                     style={{
// //                       fontSize: "1.25rem",
// //                       marginRight: theme.spacing(1),
// //                     }}
// //                   />
// //                   {data.profile.phone}
// //                 </Typography>
// //                 <Typography sx={{ display: "flex", alignItems: "center" }}>
// //                   <Icon
// //                     icon="mi:email"
// //                     style={{
// //                       fontSize: "1.25rem",
// //                       marginRight: theme.spacing(1),
// //                     }}
// //                   />
// //                   {data.profile.email}
// //                 </Typography>
// //               </Box>
// //             </Grid>
// //           </Grid>
// //         </Box>

// //         <Typography
// //           variant="h6"
// //           sx={{
// //             mb: 2,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             mt: 2,
// //             px: 2,
// //             py: 0.5,
// //             borderRadius: 1,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           Objective
// //         </Typography>
// //         <Box elevation={0} sx={{ mx: 5, borderRadius: 2, mb: 3 }}>
// //           <Box sx={{ display: "flex", justifyContent: "space-between" }}>
// //             <Typography
// //               variant="body1"
// //               sx={{ fontWeight: "medium", color: colors.primary }}
// //             >
// //               As a passionate software developer, my objective is to leverage my
// //               skills and expertise to contribute effectively to innovative
// //               projects. With a strong foundation in software development, I aim
// //               to collaborate with diverse teams, solve complex problems, and
// //               continuously enhance my knowledge in the ever-evolving field of
// //               technology.
// //             </Typography>
// //           </Box>
// //         </Box>

// //         <Typography
// //           variant="h6"
// //           sx={{
// //             mb: 2,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             mt: 2,
// //             px: 2,
// //             py: 0.5,
// //             borderRadius: 1,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           Education
// //         </Typography>

// //         {data.education.map((edu, index) => (
// //           <Box elevation={0} key={index} sx={{ mx: 5, borderRadius: 2, mb: 3 }}>
// //             <Box sx={{ display: "flex", justifyContent: "space-between" }}>
// //               <Typography
// //                 variant="body1"
// //                 sx={{ fontWeight: "medium", color: colors.primary }}
// //               >
// //                 <Icon
// //                   icon="lucide:school"
// //                   style={{ verticalAlign: "middle", marginRight: 8 }}
// //                 />
// //                 {`${edu.degree} from ${edu.school}`}
// //               </Typography>
// //               <Typography
// //                 variant="body2"
// //                 sx={{ color: colors.secondary }}
// //               >{`${edu.percentage}% - ${edu.passoutYear}`}</Typography>
// //             </Box>
// //           </Box>
// //         ))}

// //         <Typography
// //           variant="h5"
// //           sx={{
// //             mb: 2,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             px: 2,
// //             py: 0.5,
// //             borderRadius: 1,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           Skills
// //         </Typography>
// //         <Box
// //           sx={{
// //             display: "flex",
// //             flexWrap: "wrap",
// //             gap: 2,
// //             ml: 3,
// //             mb: 3,
// //             mx: 5,
// //           }}
// //         >
// //           {data.skills.map((skill, index) => (
// //             <Box
// //               key={index}
// //               sx={{
// //                 px: 2,
// //                 py: 1,
// //                 bgcolor: "#72C3FF",
// //                 borderRadius: "5px",
// //               }}
// //             >
// //               {skill}
// //             </Box>
// //           ))}
// //         </Box>

// //         <Typography
// //           variant="h5"
// //           sx={{
// //             mb: 2,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             px: 2,
// //             py: 0.5,
// //             borderRadius: 1,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           Experience
// //         </Typography>
// //         {data.internship.map((intern, index) => (
// //           <Box
// //             elevation={0}
// //             key={index}
// //             sx={{ mb: 1, mx: 3, borderRadius: 2, px: 2, pb: 1 }}
// //           >
// //             <Box sx={{ display: "flex", justifyContent: "space-between" }}>
// //               <Typography
// //                 variant="body1"
// //                 sx={{ fontWeight: "medium", color: colors.primary }}
// //               >
// //                 <Icon
// //                   icon="pajamas:work"
// //                   style={{ verticalAlign: "middle", marginRight: 8 }}
// //                 />
// //                 {`${intern.jobTitle} at ${intern.companyName}`}
// //               </Typography>
// //               <Typography
// //                 variant="body2"
// //                 sx={{ color: colors.secondary }}
// //               >{`${intern.location} (${intern.fromDate} to ${intern.toDate})`}</Typography>
// //             </Box>
// //             <Typography variant="body2">{`${intern.roleDescription}`}</Typography>
// //           </Box>
// //         ))}

// //         <Typography
// //           variant="h5"
// //           sx={{
// //             mb: 1,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             px: 2,
// //             borderRadius: 1,
// //             py: 0.5,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           AICTE Eduskills Virtual Internship
// //         </Typography>
// //         {data.aicte_internship.map((project, index) => (
// //           <Box key={index} sx={{ mx: 3, px: 2, py: 1, borderRadius: 2 }}>
// //             <Box sx={{ display: "flex", justifyContent: "space-between" }}>
// //               <Typography
// //                 variant="body1"
// //                 sx={{ fontWeight: "medium", color: colors.primary }}
// //               >
// //                 <Icon
// //                   icon="fa6-solid:graduation-cap"
// //                   style={{ verticalAlign: "middle", marginRight: 8 }}
// //                 />
// //                 {project.domain}
// //               </Typography>
// //               <Typography
// //                 variant="body2"
// //                 sx={{ color: colors.secondary }}
// //               >{`${format(
// //                 new Date(`${project.start_date}`),
// //                 "dd MMM yyyy"
// //               )} - ${format(
// //                 new Date(`${project.end_date}`),
// //                 "dd MMM yyyy"
// //               )}`}</Typography>
// //             </Box>
// //           </Box>
// //         ))}

// //         <Typography
// //           variant="h5"
// //           sx={{
// //             mb: 1,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             px: 2,
// //             mt: 1,
// //             borderRadius: 1,
// //             py: 0.5,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           Projects
// //         </Typography>
// //         {data.projects.map((project, index) => (
// //           <Box key={index} sx={{ mb: 2, mx: 3, p: 2, borderRadius: 2 }}>
// //             <Box>
// //               <Typography
// //                 variant="body1"
// //                 sx={{ fontWeight: "medium", color: colors.primary }}
// //               >
// //                 <Icon
// //                   icon="eos-icons:project"
// //                   style={{ verticalAlign: "middle", marginRight: 8 }}
// //                 />
// //                 {project.projectTitle}
// //               </Typography>
// //               <Typography variant="body2" sx={{ color: colors.secondary }}>
// //                 {project.projectDescription}
// //               </Typography>
// //             </Box>
// //           </Box>
// //         ))}

// //         <Box
// //           sx={{
// //             display: "flex",
// //             justifyContent: "center",
// //             mt: 3,
// //           }}
// //         >
// //           <Barcode value={barcodeValue} width={1} height={30} />
// //         </Box>
// //         <Typography
// //           variant="body2"
// //           sx={{
// //             textAlign: "center",
// //             mt: 1,
// //             color: colors.secondary,
// //           }}
// //         >
// //           Barcode for 30 days validity provider EduSkills Foundation
// //         </Typography>
// //       </Paper>
// //       <Button
// //         variant="contained"
// //         sx={{ mt: 3 }}
// //         onClick={downloadPDF}
// //         color="primary"
// //       >
// //         Download PDF
// //       </Button>
// //     </Container>
// //   );
// // };

// // export default Resume;
// import React from "react";
// import {
//   Container,
//   Typography,
//   Box,
//   Avatar,
//   Grid,
//   Paper,
//   useTheme,
//   Button,
// } from "@mui/material";
// import { Icon } from "@iconify/react";
// import { QRCodeSVG } from "qrcode.react"; // Make sure this is imported correctly
// import { addDays, format } from "date-fns";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import logo from "../../../assets/imgs/icon.svg";

// const Resume = ({ data, userId }) => {
//   const theme = useTheme();
//   const currentDate = new Date();
//   const validityEndDate = addDays(currentDate, 30);
//   const formattedEndDate = format(validityEndDate, "MMM dd yyyy");
//   const qrCodeValue = userId
//     ? `http://ec2-15-206-231-196.ap-south-1.compute.amazonaws.com/api/v1/talent/connect/user_details/${userId}`
//     : formattedEndDate;
//   const formattedDate = format(new Date("2024-04-12T05:55:38"), "dd MMM yyyy");
//   const colors = {
//     primary: "#263238",
//     secondary: "#546e7a",
//     accent: "#0E619F",
//     background: "#ffff",
//     white: "#ffff",
//   };

//   const headerBackground = {
//     background: `linear-gradient(135deg, ${colors.accent} 0%, ${colors.secondary} 100%)`,
//     color: colors.white,
//     borderRadius: "5px 5px 0 0",
//     padding: theme.spacing(2),
//   };

//   // const downloadPDF = () => {
//   //   const input = document.getElementById("resume-content");
//   //   const pdf = new jsPDF("p", "mm", "a4");

//   //   html2canvas(input).then((canvas) => {
//   //     const imgData = canvas.toDataURL("image/png");
//   //     const imgProps = pdf.getImageProperties(imgData);
//   //     const pdfWidth = pdf.internal.pageSize.getWidth();
//   //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
//   //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
//   //     pdf.save(`${data.profile.name}_Resume.pdf`);
//   //   });
//   // };
//   const downloadPDF = () => {
//     const input = document.getElementById("resume-content");
//     const pdf = new jsPDF("p", "mm", "a4");

//     // Convert HTML content to canvas
//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL("image/png");

//       // Add logo image directly to the canvas
//       const logoImg = new Image();
//       logoImg.src = logo;
//       logoImg.onload = () => {
//         const ctx = canvas.getContext("2d");
//         ctx.drawImage(logoImg, 20, 20, 50, 50); // Adjust position and size as needed

//         // Add canvas to PDF
//         const pdfWidth = pdf.internal.pageSize.getWidth();
//         const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
//         pdf.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);

//         // Save the PDF
//         pdf.save(`${data.profile.name}_Resume.pdf`);
//       };
//     });
//   };

//   return (
//     <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
//       <Paper
//         id="resume-content"
//         elevation={6}
//         sx={{
//           bgcolor: colors.background,
//           pb: 2,
//           height: 1140,
//           position: "relative",
//         }}
//       >
//         <Box sx={headerBackground}>
//           <Grid container spacing={2} alignItems="center">
//             <Grid item xs={12} sm={4}>
//               <Box sx={{ display: "flex", justifyContent: "center" }}>
//                 <Avatar
//                   src={data?.profile?.profile_pic}
//                   sx={{ width: 100, height: 100 }}
//                 />
//               </Box>
//             </Grid>
//             <Grid item xs={12} sm={8}>
//               <Typography variant="h4" sx={{ fontWeight: "bold" }}>
//                 {data?.profile?.name}
//               </Typography>
//               {/* <Typography
//                 variant="subtitle1"
//                 sx={{ fontSize: "1.1rem", fontWeight: "medium" }}
//               >
//                 {data?.objective}
//               </Typography> */}
//               <Box sx={{ mt: 1 }}>
//                 <Typography
//                   sx={{ display: "flex", alignItems: "center", mb: 1 }}
//                 >
//                   <Icon
//                     icon="el:phone-alt"
//                     style={{
//                       fontSize: "1.25rem",
//                       marginRight: theme.spacing(1),
//                     }}
//                   />
//                   {data?.profile?.phone}
//                 </Typography>
//                 <Typography sx={{ display: "flex", alignItems: "center" }}>
//                   <Icon
//                     icon="mi:email"
//                     style={{
//                       fontSize: "1.25rem",
//                       marginRight: theme.spacing(1),
//                     }}
//                   />
//                   {data?.profile?.email}
//                 </Typography>
//               </Box>
//             </Grid>
//           </Grid>
//         </Box>
//         {data?.objective.length > 0 && (
//           <>
//             <Typography
//               variant="h6"
//               sx={{
//                 mb: 2,
//                 fontWeight: "bold",
//                 color: colors.white,
//                 mx: 3,
//                 mt: 2,
//                 px: 2,
//                 py: 0.5,
//                 borderRadius: 1,
//                 bgcolor: colors.accent,
//               }}
//             >
//               Objective
//             </Typography>
//             <Box elevation={0} sx={{ mx: 5, borderRadius: 2, mb: 3 }}>
//               <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//                 <Typography
//                   variant="body1"
//                   sx={{ fontWeight: "medium", color: colors.primary }}
//                 >
//                   {data?.objective}
//                 </Typography>
//               </Box>
//             </Box>
//           </>
//         )}

//         {data?.education.length > 0 && (
//           <>
//             <Typography
//               variant="h6"
//               sx={{
//                 mb: 2,
//                 fontWeight: "bold",
//                 color: colors.white,
//                 mx: 3,
//                 mt: 1,
//                 px: 2,
//                 py: 0.5,
//                 borderRadius: 1,
//                 bgcolor: colors.accent,
//               }}
//             >
//               Education
//             </Typography>

//             {data?.education?.map((edu, index) => (
//               <Box key={index} sx={{ mx: 5, borderRadius: 2, mb: 1 }}>
//                 <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//                   <Typography
//                     variant="body2"
//                     sx={{ fontWeight: "medium", color: colors.primary }}
//                   >
//                     <Icon
//                       icon="lucide:school"
//                       style={{ verticalAlign: "middle", marginRight: 8 }}
//                     />
//                     {`${edu.degree} from ${edu.school}`}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     sx={{ color: colors.secondary }}
//                   >{`${edu?.percentage}% - ${edu?.passoutYear}`}</Typography>
//                 </Box>
//               </Box>
//             ))}
//           </>
//         )}

//         {data?.skills.length > 0 && (
//           <>
//             <Typography
//               variant="h5"
//               sx={{
//                 mb: 2,
//                 fontWeight: "bold",
//                 color: colors.white,
//                 mx: 3,
//                 px: 2,
//                 mt: 1.5,
//                 py: 0.5,
//                 borderRadius: 1,
//                 bgcolor: colors.accent,
//               }}
//             >
//               Skills
//             </Typography>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexWrap: "wrap",
//                 gap: 2,
//                 ml: 3,
//                 mb: 3,
//                 mx: 5,
//               }}
//             >
//               {data?.skills?.map((skill, index) => (
//                 <Box
//                   key={index}
//                   sx={{
//                     px: 1.5,
//                     py: 0.5,
//                     fontSize: 10,
//                     bgcolor: "#72C3FF",
//                     borderRadius: "5px",
//                   }}
//                 >
//                   {skill}
//                 </Box>
//               ))}
//             </Box>
//           </>
//         )}

//         {data?.internship.length > 0 && (
//           <>
//             <Typography
//               variant="h5"
//               sx={{
//                 mb: 2,
//                 fontWeight: "bold",
//                 color: colors.white,
//                 mx: 3,
//                 px: 2,
//                 py: 0.5,
//                 borderRadius: 1,
//                 bgcolor: colors.accent,
//               }}
//             >
//               Experience
//             </Typography>
//             {data?.internship?.slice(0, 1).map((intern, index) => (
//               <Box
//                 elevation={0}
//                 key={index}
//                 sx={{ mb: 1, mx: 3, borderRadius: 2, px: 2, pb: 1 }}
//               >
//                 <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//                   <Typography
//                     variant="body1"
//                     sx={{ fontWeight: "medium", color: colors.primary }}
//                   >
//                     <Icon
//                       icon="pajamas:work"
//                       style={{ verticalAlign: "middle", marginRight: 8 }}
//                     />
//                     {`${intern?.jobTitle} at ${intern?.companyName}`}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     sx={{ color: colors.secondary }}
//                   >{`${intern?.location} (${intern?.fromDate} to ${intern?.toDate})`}</Typography>
//                 </Box>
//                 <Typography variant="body2" color="black">{`${intern?.roleDescription}`}</Typography>
//               </Box>
//             ))}
//           </>
//         )}

//         {data?.aicte_internship.length > 0 && (
//           <>
//             <Typography
//               variant="h5"
//               sx={{
//                 mb: 1,
//                 fontWeight: "bold",
//                 color: colors.white,
//                 mx: 3,
//                 px: 2,
//                 borderRadius: 1,
//                 py: 0.5,
//                 bgcolor: colors.accent,
//               }}
//             >
//               AICTE Eduskills Virtual Internship
//             </Typography>
//             {data?.aicte_internship?.map((project, index) => (
//               <Box key={index} sx={{ mx: 3, px: 2, py: 0.5, borderRadius: 2 }}>
//                 <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//                   <Typography
//                     variant="body2"
//                     sx={{ fontWeight: "medium", color: colors.primary }}
//                   >
//                     <Icon
//                       icon="fa6-solid:graduation-cap"
//                       style={{ verticalAlign: "middle", marginRight: 8 }}
//                     />
//                     {project?.domain}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     sx={{ color: colors.secondary }}
//                   >{`${
//                     project?.start_date &&
//                     format(new Date(`${project?.start_date}`), "MMM yyyy")
//                   } - ${
//                     project?.end_date &&
//                     format(new Date(`${project?.end_date}`), "MMM yyyy")
//                   }`}</Typography>
//                 </Box>
//               </Box>
//             ))}
//           </>
//         )}
//         {data?.projects.length > 0 && (
//           <>
//             <Typography
//               variant="h5"
//               sx={{
//                 mb: 1,
//                 fontWeight: "bold",
//                 color: colors.white,
//                 mx: 3,
//                 px: 2,
//                 mt: 1,
//                 borderRadius: 1,
//                 py: 0.5,
//                 bgcolor: colors.accent,
//               }}
//             >
//               Projects
//             </Typography>
//             {data?.projects?.slice(0, 2).map((project, index) => (
//               <Box key={index} sx={{ mb: 1, mx: 3, px: 2, borderRadius: 2 }}>
//                 <Box>
//                   <Typography
//                     variant="body1"
//                     sx={{ fontWeight: "medium", color: colors.primary }}
//                   >
//                     <Icon
//                       icon="eos-icons:project"
//                       style={{ verticalAlign: "middle", marginRight: 8 }}
//                     />
//                     {project?.projectTitle}
//                   </Typography>
//                   <Typography variant="body2" sx={{ color: colors.secondary }}>
//                     {project?.projectDescription}
//                   </Typography>
//                 </Box>
//               </Box>
//             ))}
//           </>
//         )}

//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             mt: 2,
//             gap: 1,
//             position: "absolute",
//             bottom: 0,
//             right: 20,
//             mb: 2,
//           }}
//         >
//           <QRCodeSVG
//             value={qrCodeValue}
//             size={70}
//             imageSettings={{
//               src: logo,
//               x: 27.5,
//               y: 27.5,
//               height: 14,
//               width: 14,
//               // excavate: true,
//             }}
//           />
//           <Typography
//             variant="body2"
//             sx={{
//               // textAlign: "center",
//               // mt: 1,
//               maxWidth: 80,
//               color: colors.secondary,
//             }}
//           >
//             Resume Verification Code
//           </Typography>
//         </Box>
//       </Paper>
//       <Button
//         variant="contained"
//         sx={{ mt: 3 }}
//         onClick={downloadPDF}
//         color="primary"
//       >
//         Download PDF
//       </Button>
//     </Container>
//   );
// };

// export default Resume;

// // import React from "react";
// // import {
// //   Container,
// //   Typography,
// //   Box,
// //   Avatar,
// //   Grid,
// //   Paper,
// //   Button,
// // } from "@mui/material";
// // import { Icon } from "@iconify/react";
// // import Barcode from "react-barcode";
// // import { addDays, format } from "date-fns";
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";

// // const Resume = ({ data }) => {
// //   const currentDate = new Date();
// //   const validityEndDate = addDays(currentDate, 30);
// //   const formattedEndDate = format(validityEndDate, "MMM dd yyyy");
// //   const barcodeValue = `EduSkills Valid for ${formattedEndDate}`;
// //   const formattedDate = format(new Date("2024-04-12T05:55:38"), "dd MMM yyyy");
// //   const colors = {
// //     primary: "#263238",
// //     secondary: "#546e7a",
// //     accent: "#0E619F",
// //     background: "#ffff",
// //     white: "#ffff",
// //   };

// //   const headerBackground = {
// //     background: `linear-gradient(135deg, ${colors.accent} 0%, ${colors.secondary} 100%)`,
// //     color: colors.white,
// //     borderRadius: "5px 5px 0 0",
// //     padding: "16px",
// //   };

// //   const downloadPDF = () => {
// //     const input = document.getElementById("resume-content");
// //     const pdf = new jsPDF("p", "mm", "a4");

// //     html2canvas(input).then((canvas) => {
// //       const imgData = canvas.toDataURL("image/png");
// //       const imgProps = pdf.getImageProperties(imgData);
// //       const pdfWidth = pdf.internal.pageSize.getWidth();
// //       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
// //       pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
// //       pdf.save(`${data.profile.name}_Resume.pdf`);
// //     });
// //   };

// //   return (
// //     <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
// //       <Paper
// //         id="resume-content"
// //         elevation={6}
// //         sx={{ bgcolor: colors.background, pb: 2, maxWidth: "800px", margin: "0 auto" }}
// //       >
// //         <Box sx={headerBackground}>
// //           <Grid container spacing={2} alignItems="center">
// //             <Grid item xs={4}>
// //               <Box sx={{ display: "flex", justifyContent: "center" }}>
// //                 <Avatar
// //                   src={data.profile.profile_pic}
// //                   sx={{ width: 140, height: 140 }}
// //                 />
// //               </Box>
// //             </Grid>
// //             <Grid item xs={8}>
// //               <Typography
// //                 variant="h4"
// //                 sx={{ fontWeight: "bold", marginBottom: 1 }}
// //               >
// //                 {data.profile.name}
// //               </Typography>
// //               <Typography
// //                 variant="subtitle1"
// //                 sx={{ fontSize: "1.1rem", fontWeight: "medium" }}
// //               >
// //                 {data.objective}
// //               </Typography>
// //               <Box sx={{ mt: 2 }}>
// //                 <Typography
// //                   sx={{ display: "flex", alignItems: "center", mb: 1 }}
// //                 >
// //                   <Icon
// //                     icon="el:phone-alt"
// //                     style={{
// //                       fontSize: "1.25rem",
// //                       marginRight: "8px",
// //                     }}
// //                   />
// //                   {data.profile.phone}
// //                 </Typography>
// //                 <Typography sx={{ display: "flex", alignItems: "center" }}>
// //                   <Icon
// //                     icon="mi:email"
// //                     style={{
// //                       fontSize: "1.25rem",
// //                       marginRight: "8px",
// //                     }}
// //                   />
// //                   {data.profile.email}
// //                 </Typography>
// //               </Box>
// //             </Grid>
// //           </Grid>
// //         </Box>

// //         <Typography
// //           variant="h6"
// //           sx={{
// //             mb: 2,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             mt: 2,
// //             px: 2,
// //             py: 0.5,
// //             borderRadius: 1,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           Objective
// //         </Typography>
// //         <Box elevation={0} sx={{ mx: 5, borderRadius: 2, mb: 3 }}>
// //           <Box sx={{ display: "flex", justifyContent: "space-between" }}>
// //             <Typography
// //               variant="body1"
// //               sx={{ fontWeight: "medium", color: colors.primary }}
// //             >
// //               As a passionate software developer, my objective is to leverage my
// //               skills and expertise to contribute effectively to innovative
// //               projects. With a strong foundation in software development, I aim
// //               to collaborate with diverse teams, solve complex problems, and
// //               continuously enhance my knowledge in the ever-evolving field of
// //               technology.
// //             </Typography>
// //           </Box>
// //         </Box>

// //         <Typography
// //           variant="h6"
// //           sx={{
// //             mb: 2,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             mt: 2,
// //             px: 2,
// //             py: 0.5,
// //             borderRadius: 1,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           Education
// //         </Typography>

// //         {data.education.map((edu, index) => (
// //           <Box elevation={0} key={index} sx={{ mx: 5, borderRadius: 2, mb: 3 }}>
// //             <Box sx={{ display: "flex", justifyContent: "space-between" }}>
// //               <Typography
// //                 variant="body1"
// //                 sx={{ fontWeight: "medium", color: colors.primary }}
// //               >
// //                 <Icon
// //                   icon="lucide:school"
// //                   style={{ verticalAlign: "middle", marginRight: 8 }}
// //                 />
// //                 {`${edu.degree} from ${edu.school}`}
// //               </Typography>
// //               <Typography
// //                 variant="body2"
// //                 sx={{ color: colors.secondary }}
// //               >{`${edu.percentage}% - ${edu.passoutYear}`}</Typography>
// //             </Box>
// //           </Box>
// //         ))}

// //         <Typography
// //           variant="h5"
// //           sx={{
// //             mb: 2,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             px: 2,
// //             py: 0.5,
// //             borderRadius: 1,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           Skills
// //         </Typography>
// //         <Box
// //           sx={{
// //             display: "flex",
// //             flexWrap: "wrap",
// //             gap: 2,
// //             ml: 3,
// //             mb: 3,
// //             mx: 5,
// //           }}
// //         >
// //           {data.skills.map((skill, index) => (
// //             <Box
// //               key={index}
// //               sx={{
// //                 px: 2,
// //                 py: 1,
// //                 bgcolor: "#72C3FF",
// //                 borderRadius: "5px",
// //               }}
// //             >
// //               {skill}
// //             </Box>
// //           ))}
// //         </Box>

// //         <Typography
// //           variant="h5"
// //           sx={{
// //             mb: 2,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             px: 2,
// //             py: 0.5,
// //             borderRadius: 1,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           Experience
// //         </Typography>
// //         {data.internship.map((intern, index) => (
// //           <Box
// //             elevation={0}
// //             key={index}
// //             sx={{ mb: 1, mx: 3, borderRadius: 2, px: 2, pb: 1 }}
// //           >
// //             <Box sx={{ display: "flex", justifyContent: "space-between" }}>
// //               <Typography
// //                 variant="body1"
// //                 sx={{ fontWeight: "medium", color: colors.primary }}
// //               >
// //                 <Icon
// //                   icon="pajamas:work"
// //                   style={{ verticalAlign: "middle", marginRight: 8 }}
// //                 />
// //                 {`${intern.jobTitle} at ${intern.companyName}`}
// //               </Typography>
// //               <Typography
// //                 variant="body2"
// //                 sx={{ color: colors.secondary }}
// //               >{`${intern.location} (${intern.fromDate} to ${intern.toDate})`}</Typography>
// //             </Box>
// //             <Typography variant="body2">{`${intern.roleDescription}`}</Typography>
// //           </Box>
// //         ))}

// //         <Typography
// //           variant="h5"
// //           sx={{
// //             mb: 1,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             px: 2,
// //             borderRadius: 1,
// //             py: 0.5,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           AICTE Eduskills Virtual Internship
        // </Typography>
        // {data.aicte_internship.map((project, index) => (
        //   <Box key={index} sx={{ mx: 3, px: 2, py: 1, borderRadius: 2 }}>
        //     <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        //       <Typography
        //         variant="body1"
        //         sx={{ fontWeight: "medium", color: colors.primary }}
        //       >
        //         <Icon
        //           icon="fa6-solid:graduation-cap"
        //           style={{ verticalAlign: "middle", marginRight: 8 }}
        //         />
        //         {project.domain}
        //       </Typography>
        //       <Typography
        //         variant="body2"
        //         sx={{ color: colors.secondary }}
        //       >{`${format(
        //         new Date(`${project.start_date}`),
        //         "dd MMM yyyy"
        //       )} - ${format(
        //         new Date(`${project.end_date}`),
        //         "dd MMM yyyy"
        //       )}`}</Typography>
// //             </Box>
// //           </Box>
// //         ))}

// //         <Typography
// //           variant="h5"
// //           sx={{
// //             mb: 1,
// //             fontWeight: "bold",
// //             color: colors.white,
// //             mx: 3,
// //             px: 2,
// //             mt: 1,
// //             borderRadius: 1,
// //             py: 0.5,
// //             bgcolor: colors.accent,
// //           }}
// //         >
// //           Projects
// //         </Typography>
// //         {data.projects.map((project, index) => (
// //           <Box key={index} sx={{ mb: 2, mx: 3, p: 2, borderRadius: 2 }}>
// //             <Box>
// //               <Typography
// //                 variant="body1"
// //                 sx={{ fontWeight: "medium", color: colors.primary }}
// //               >
// //                 <Icon
// //                   icon="eos-icons:project"
// //                   style={{ verticalAlign: "middle", marginRight: 8 }}
// //                 />
// //                 {project.projectTitle}
// //               </Typography>
// //               <Typography variant="body2" sx={{ color: colors.secondary }}>
// //                 {project.projectDescription}
// //               </Typography>
// //             </Box>
// //           </Box>
// //         ))}

// //         <Box
// //           sx={{
// //             display: "flex",
// //             justifyContent: "center",
// //             mt: 3,
// //           }}
// //         >
// //           <Barcode value={barcodeValue} width={1} height={30} />
// //         </Box>
// //         <Typography
// //           variant="body2"
// //           sx={{
// //             textAlign: "center",
// //             mt: 1,
// //             color: colors.secondary,
// //           }}
// //         >
// //           Barcode for 30 days validity provider EduSkills Foundation
// //         </Typography>
// //       </Paper>
// //       <Button
// //         variant="contained"
// //         sx={{ mt: 3 }}
// //         onClick={downloadPDF}
// //         color="primary"
// //       >
// //         Download PDF
// //       </Button>
// //     </Container>
// //   );
// // };

// // export default Resume;

import React from "react";
import {
  Container,
  Typography,
  Box,
  Avatar,
  Grid,
  Paper,
  useTheme,
  Button,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { QRCodeSVG } from "qrcode.react"; // Make sure this is imported correctly
import { addDays, format } from "date-fns";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Barcode from "react-barcode";
import logo from "../../../assets/imgs/icon.svg";

const Resume = ({ data, userId }) => {
  const theme = useTheme();
  const currentDate = new Date();
  const validityEndDate = addDays(currentDate, 30);
  const formattedEndDate = format(validityEndDate, "MMM dd yyyy");
  const qrCodeValue = userId
    ? `http://ec2-15-206-231-196.ap-south-1.compute.amazonaws.com/api/v1/talent/connect/user_details/${userId}`
    : formattedEndDate;
  const formattedDate = format(new Date("2024-04-12T05:55:38"), "dd MMM yyyy");
  const colors = {
    primary: "#263238",
    secondary: "#546e7a",
    accent: "#0E619F",
    background: "#ffff",
    white: "#ffff",
  };

  const headerBackground = {
    background: `linear-gradient(135deg, ${colors.accent} 0%, ${colors.secondary} 100%)`,
    color: colors.white,
    borderRadius: "5px 5px 0 0",
    padding: theme.spacing(2),
  };

  const downloadPDF = () => {
    const input = document.getElementById("resume-content");
    const pdf = new jsPDF("p", "mm", "a4");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Add logo image directly to the canvas
      const logoImg = new Image();
      logoImg.src = logo;
      logoImg.onload = () => {
        const ctx = canvas.getContext("2d");
        ctx.drawImage(logoImg, 20, 20, 50, 50); // Adjust position and size as needed

        // Add canvas to PDF
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        pdf.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);

        // Save the PDF
        pdf.save(`${data.profile.name}_Resume.pdf`);
      };
    });
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper
        id="resume-content"
        elevation={6}
        sx={{
          bgcolor: colors.background,
          pb: 2,
          height: 1140,
          position: "relative",
        }}
      >
        <Box sx={headerBackground}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Avatar
                  src={data?.profile?.profile_pic}
                  sx={{ width: 100, height: 100 }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {data?.profile?.name}
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <Icon
                    icon="el:phone-alt"
                    style={{
                      fontSize: "1.25rem",
                      marginRight: theme.spacing(1),
                    }}
                  />
                  {data?.profile?.phone}
                </Typography>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  <Icon
                    icon="mi:email"
                    style={{
                      fontSize: "1.25rem",
                      marginRight: theme.spacing(1),
                    }}
                  />
                  {data?.profile?.email}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {data?.objective?.length > 0 && (
          <>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                fontWeight: "bold",
                color: colors.white,
                mx: 3,
                mt: 2,
                px: 2,
                py: 0.5,
                borderRadius: 1,
                bgcolor: colors.accent,
              }}
            >
              Objective
            </Typography>
            <Box elevation={0} sx={{ mx: 5, borderRadius: 2, mb: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "medium", color: colors.primary }}
                >
                  {data?.objective}
                </Typography>
              </Box>
            </Box>
          </>
        )}

        {data?.education?.length > 0 && (
          <>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                fontWeight: "bold",
                color: colors.white,
                mx: 3,
                mt: 1,
                px: 2,
                py: 0.5,
                borderRadius: 1,
                bgcolor: colors.accent,
              }}
            >
              Education
            </Typography>

            {data?.education?.map((edu, index) => (
              <Box key={index} sx={{ mx: 5, borderRadius: 2, mb: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "medium", color: colors.primary }}
                  >
                    <Icon
                      icon="lucide:school"
                      style={{ verticalAlign: "middle", marginRight: 8 }}
                    />
                    {`${edu.degree} from ${edu.school}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: colors.secondary }}
                  >{`${edu?.percentage}% - ${edu?.passoutYear}`}</Typography>
                </Box>
              </Box>
            ))}
          </>
        )}

        {data?.skills?.length > 0 && (
          <>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: "bold",
                color: colors.white,
                mx: 3,
                px: 2,
                mt: 1.5,
                py: 0.5,
                borderRadius: 1,
                bgcolor: colors.accent,
              }}
            >
              Skills
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                ml: 3,
                mb: 3,
                mx: 5,
              }}
            >
              {data?.skills?.map((skill, index) => (
                <Box
                  key={index}
                  sx={{
                    px: 1.5,
                    py: 0.5,
                    fontSize: 10,
                    bgcolor: "#72C3FF",
                    borderRadius: "5px",
                  }}
                >
                  {skill}
                </Box>
              ))}
            </Box>
          </>
        )}

        {data?.internship?.length > 0 && (
          <>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: "bold",
                color: colors.white,
                mx: 3,
                px: 2,
                py: 0.5,
                borderRadius: 1,
                bgcolor: colors.accent,
              }}
            >
              Experience
            </Typography>
            {data?.internship?.slice(0, 1).map((intern, index) => (
              <Box
                elevation={0}
                key={index}
                sx={{ mb: 1, mx: 3, borderRadius: 2, px: 2, pb: 1 }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "medium", color: colors.primary }}
                  >
                    <Icon
                      icon="pajamas:work"
                      style={{ verticalAlign: "middle", marginRight: 8 }}
                    />
                    {`${intern?.jobTitle} at ${intern?.companyName}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: colors.secondary }}
                  >{`${intern?.location} (${intern?.fromDate} to ${intern?.toDate})`}</Typography>
                </Box>
                <Typography variant="body2" color="black">{`${intern?.roleDescription}`}</Typography>
              </Box>
            ))}
          </>
        )}
        
        {data?.projects?.length > 0 && (
          <>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: "bold",
                color: colors.white,
                mx: 3,
                px: 2,
                py: 0.5,
                borderRadius: 1,
                bgcolor: colors.accent,
              }}
            >
              Projects
            </Typography>
            {data?.projects?.map((project, index) => (
              <Box
                elevation={0}
                key={index}
                sx={{ mb: 1, mx: 3, borderRadius: 2, px: 2, pb: 1 }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "medium", color: colors.primary }}
                  >
                    <Icon
                      icon="clarity:tasks-solid"
                      style={{ verticalAlign: "middle", marginRight: 8 }}
                    />
                    {`${project?.projectTitle}`}
                  </Typography>
                </Box>
                <Typography variant="body2" color="black">{`${project?.projectDescription}`}</Typography>
              </Box>
            ))}
          </>
        )}

        <Box
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
            textAlign: "center",
          }}
        >
          <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            mt: 1,
            color: colors.secondary,
          }}
        >
          Barcode for 30 days validity provider EduSkills Foundation
        </Typography>
          <QRCodeSVG value={qrCodeValue} size={64} />
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <Barcode value={qrCodeValue} width={1} height={30} />
        </Box>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            mt: 1,
            color: colors.secondary,
          }}
        >
          Barcode for 30 days validity provider EduSkills Foundation
        </Typography> */}
      </Paper>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={downloadPDF}
      >
        Download PDF
      </Button>
    </Container>
  );
};

export default Resume;
